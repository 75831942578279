export default class NotifcationPopup {
  constructor() {
    if (window.location.pathname !== '/winkelmand/') return;

    const popup = document.querySelector('.notification-popup');
    const close = document.querySelector('.notification-popup__button');
    const rememberMeCheckbox = document.getElementById('notification-check');

    close.addEventListener('click', () => {
      document.body.classList.remove('opacity');
      popup.classList.remove('active');

      if (rememberMeCheckbox.checked) {
        localStorage.setItem('visitedShop', 'true');
      }
    });

    if (!sessionStorage.getItem('visitedShop') && !localStorage.getItem('visitedShop')) {
      document.body.classList.add('opacity');
      popup.classList.add('active');
      sessionStorage.setItem('visitedShop', 'true');
    }
  }
}
