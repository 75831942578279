import $ from 'jquery';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class WooCommerceSingleProduct {
  constructor() {
    if (document.body.classList.contains('single-product')) {
      this.quantityInput();
    }
    this.quickAdd();
  }

  quantityInput() {
    $('.quantity').on('click', '.quantity-button.minus', function(e) {
      e.preventDefault();
      const $input = $(this).next('input.qty');
      let val = parseInt($input.val());
      let step = $input.attr('step');
      step = 'undefined' !== typeof step ? parseInt(step) : 1;
      if (val > 0) {
        $input.val(val - step);
      }
    });

    $('.quantity').on('click', '.quantity-button.plus', function(e) {
      e.preventDefault();
      const $input = $(this).prev('input.qty');
      let val = parseInt($input.val());
      let step = $input.attr('step');
      step = 'undefined' !== typeof step ? parseInt(step) : 1;
      $input.val(val + step);
    });
  }

  quickAdd() {
    const quickAddButtons = Array.from(document.querySelectorAll('button.upsells-quick-add'));

    quickAddButtons.forEach(button => {
      button.addEventListener('click', () => {
        button.querySelector('.spinner').classList.remove('hidden');
        button.classList.add('opacity-75');
        button.classList.add('pointer-events-none');

        $.ajax({
          url: window.woocommerce_params.ajax_url,
          type: 'post',
          data: {
            action: 'add_drink_to_cart',
            productId: button.getAttribute('data-product-id'),
          },
          success: function() {
            button.querySelector('.spinner').classList.add('hidden');
            Toastr.options.positionClass = 'toast-top-left';
            Toastr.success('Product toegevoegd aan winkelmand!');
            button.classList.remove('opacity-75');
            button.classList.remove('pointer-events-none');
            $(document.body).trigger('wc_fragment_refresh');
          },
        });
      });
    });
  }
}
