export default class MobileMenu {
  constructor() {
    this.mobileMenu = document.querySelector('.mobile-menu');
    this.overlay = document.querySelector('.mobile-menu-overlay');
    const menuButton = document.querySelector('.menu-button-open');
    const closeButton = document.querySelector('.menu-button-close');
    menuButton.addEventListener('click', () => {
      this._toggleMenu();
    });
    closeButton.addEventListener('click', () => {
      this._toggleMenu();
    });
  }

  _toggleMenu() {
    document.body.classList.toggle('fixed');
    this.mobileMenu.classList.toggle('is-open');
    this.overlay.classList.toggle('is-visible');
  }
}
