export default class ProductFilters {
  constructor() {
    this.filterActive = false;
    this.searchInput = document.getElementById('searchByName');
    this.productBlocks = Array.from(document.querySelectorAll('li.product'));
    this.filters = document.querySelectorAll('.product-filter');
    this.filterContainer = document.querySelector('.product-filters-container');
    this.filterList = this.filterContainer.querySelector('ul');
    this.categoryFilters();
    this._setFilterHeight();
    document.getElementById('searchByName').addEventListener('keyup', () => {
      this.searchFilter();
    });

    const resetFormButton = document.querySelector('div.search-product-form input[type=reset]');
    resetFormButton.addEventListener('click', () => {
      document.querySelector('[data-category-id=all]').click();
      this._showAllProducts();
    });
  }

  _setFilterHeight() {
    const listHeight = this.filterList.offsetHeight;
    if (listHeight > 0) {
      this.filterContainer.style.height = listHeight + 16 + 'px';
    }
  }

  categoryFilters() {
    Array.from;
    this.filters.forEach(button => {
      button.addEventListener('click', () => {
        this.filters.forEach(filter => {
          filter.classList.remove('active');
        });
        button.classList.add('active');
        const categoryId = button.getAttribute('data-category-id');
        this._setVisibleProducts(categoryId);
      });
    });
  }

  _showAllProducts() {
    return this.productBlocks.map(block => {
      block.classList.remove('hidden');
    });
  }

  _setVisibleProducts(selectedCatId) {
    this.searchInput.value = '';
    // Hide all blocks
    this.productBlocks.map(block => {
      block.classList.add('hidden');
    });

    // If all selected, show all and return
    if (selectedCatId == 'all') {
      this.filterActive = false;
      this._showAllProducts();
    }

    this.productBlocks.map(block => {
      selectedCatId = parseInt(selectedCatId);
      let productCatIds = JSON.parse(block.getAttribute('data-product-category'));

      if (productCatIds.includes(selectedCatId)) {
        block.classList.remove('hidden');
      }
    });
    this.filterActive = true;
  }

  searchFilter() {
    let filter, ul, li, i;
    filter = this.searchInput.value.toUpperCase();
    ul = document.querySelector('.product-loop');
    li = ul.querySelectorAll('.product:not(.hidden)');

    if (this.searchInput.value == '') {
      this.productBlocks.map(block => {
        block.classList.remove('hidden');
      });
      document.querySelector('[data-category-id=all]').click();
    }

    for (i = 0; i < li.length; i++) {
      let title = li[i].getAttribute('data-product-title');

      if (title.toUpperCase().indexOf(filter) > -1) {
        li[i].classList.remove('hidden');
      } else {
        li[i].classList.add('hidden');
      }
    }
  }
}
