export default class Faq {
  constructor() {
    const faqItems = document.querySelectorAll('.faq-item');

    if (faqItems.length > 0) this.init(faqItems);
  }

  init(items) {
    items.forEach(item => {
      item.addEventListener('click', () => {
        item.querySelector('.faq-answer').classList.toggle('hidden');
      });
    });
  }
}
