import Faq from './includes/faq';
import WooCommerceSingleProduct from './includes/woocommerce-single-product';
import ProductFilters from './includes/product-filters';
import WooCommerceCart from './includes/woocommerce-cart';
import WooCommerceMiniCart from './includes/woocommerce-mini-cart';
import CustomizeProduct from './includes/customize-product';
import MobileMenu from './includes/mobile-menu';
import Search from './includes/search';
import NotifcationPopup from './includes/notification-popup';

document.addEventListener('DOMContentLoaded', () => {
  new Faq();
  new Search();
  new WooCommerceMiniCart();
  new NotifcationPopup();
  if (document.body.classList.contains('post-type-archive-product')) {
    new ProductFilters();
  }
  new WooCommerceSingleProduct();
  if (document.body.classList.contains('woocommerce-cart')) {
    new WooCommerceCart();
  }
  if (window.innerWidth < 1024) {
    new MobileMenu();
  }
  new CustomizeProduct();
});
