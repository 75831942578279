import $ from 'jquery';
import { unslugify } from 'unslugify';
import tingle from 'tingle.js';
import 'tingle.js/dist/tingle.min.css';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class CustomizeProduct {
  constructor() {
    this.customizeProductButtons = document.querySelectorAll('.customize-product-btn');
    this.productData;
    this.modal;

    if (this.customizeProductButtons.length > 0) this.addEventListeners();
  }

  /**
   * Adds event listener to 'Zelf samenstellen' buttons
   *
   * @param {Object} productData
   * @param {Object[]} this.customizeProductButtons
   *
   * @returns {void}
   */
  addEventListeners() {
    this.customizeProductButtons.forEach(button => {
      button.addEventListener('click', e => {
        let productDataAttribute = e.target.getAttribute('data-product');
        this.productData = JSON.parse(productDataAttribute);
        this.showModal();
      });
    });

    $(document).on('click', 'button.add-btn', e => {
      this.addToCart(e);
    });
  }

  /**
   * Generates Micromodal.js modal
   *
   * @param {Object} button
   * @param {Array} productData
   *
   * @returns {void}
   */
  showModal() {
    this.modal = new tingle.modal({
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: 'Sluiten',
      onClose: () => {
        this.modal.destroy();
      },
      onOpen: () => {},
    });

    this.modal.setContent(`
        <div class="modal">
            <div class="bg-white rounded-xl mx-4 lg:mx-0 lg:w-[480px] overflow-hidden relative">
                <img src="${this.productData.image}" class="w-full" />
                <div class="p-4 lg:p-8">
                    <h3 class="mb-4">${this.productData.title}</h3>
                    <p class="mb-4">${this.productData.description}</p>                    
                    <div class="mb-8 grid grid-cols-2 gap-4">
                        ${Object.entries(this.productData.attributes)
                          .map(([attribute, values]) => {
                            values = [...new Set(values)];
                            return `
                                <div>
                                    <h4 class="capitalize mb-2">${attribute}</h4>
                                    <select name="${attribute}" class="attributes py-4 px-6 border border-gray-300 rounded-xl shadow bg-white w-full">        
                                    ${Object.keys(values)
                                      .map(function(key) {
                                        return (
                                          "<option value='" + values[key] + "'>" + unslugify(values[key]) + '</option>'
                                        );
                                      })
                                      .join('')}
                                    </select>
                                </div>
                            `;
                          })
                          .join('')}
                    </div>
                    <button id="AddButton${this.productData.id}" class="add-btn btn primary w-full flex justify-center">
                        Voeg toe
                        <svg xmlns="http://www.w3.org/2000/svg" class="spinner hidden ml-4 animate-spin h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    `);

    this.modal.open();
  }

  async addToCart(e) {
    const selectedAttr = document.querySelectorAll('select.attributes');
    let attributesData = {};

    let button = e.currentTarget;
    button.querySelector('.spinner').classList.remove('hidden');
    button.classList.add('opacity-75');
    button.classList.add('pointer-events-none');

    selectedAttr.forEach(attr => {
      let name = 'attribute_pa_' + attr.name;
      let value = attr.options[attr.selectedIndex].value;
      attributesData[name] = value;
    });

    let formattedData = {
      productId: this.productData.id,
      variationId: this.productData.variation_id,
      attributes: JSON.stringify(attributesData),
      quantity: 1,
    };

    const res = await $.ajax({
      url: window.woocommerce_params.ajax_url,
      type: 'post',
      data: {
        action: 'quick_add_to_cart',
        data: formattedData,
      },
    });

    if (res.success) {
      this.modal.close();
      $(document.body).trigger('wc_fragment_refresh');
      Toastr.options.positionClass = 'toast-top-left';
      Toastr.success('Product toegevoegd aan winkelmand!');
    } else {
      console.warn('Something went wrong, try again or contact site administrator');
    }
  }
}
