export default class WooCommerceMiniCart {
  constructor() {
    this._init();
    this._showBottomButton();
  }

  _init() {
    const miniCartBtns = document.querySelectorAll('.mini-cart-btn');
    miniCartBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        this.toggleMiniCart();
      });
    });
    document.querySelector('.close-mini-cart').addEventListener('click', () => {
      this.toggleMiniCart();
    });
  }

  _showBottomButton() {
    window.addEventListener('scroll', () => {
      const btn = document.querySelector('button.mini-cart-btn-bottom');
      if (btn) {
        if (window.scrollY > 400) {
          if (!btn.classList.contains('is-visible')) {
            btn.classList.add('is-visible');
          }
        } else {
          if (btn.classList.contains('is-visible')) {
            btn.classList.remove('is-visible');
          }
        }
      }
    });
  }

  toggleMiniCart() {
    document.querySelector('div.mini-cart').classList.toggle('hidden');
  }
}
