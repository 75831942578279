export default class Search {
  constructor() {
    this.searchBar = document.querySelector('.search-bar');
    const searchBarButtons = Array.from(
      document.querySelectorAll('.search-bar-btn')
    );

    searchBarButtons.forEach(button => {
      button.addEventListener('click', () => {
        this._toggleSearchbar();
      });
    });
  }

  _toggleSearchbar() {
    // document.body.classList.toggle('fixed');
    this.searchBar.classList.toggle('is-visible');
  }
}
