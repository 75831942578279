import $ from 'jquery';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class WooCommerceCart {
  constructor() {
    this._init();
  }

  _init() {
    $(document).on('click', 'button.plus, button.minus', e => {
      this._changeQuanity(e.currentTarget);
    });
    this._cartQuickAdd();
  }

  _changeQuanity(button) {
    let el = $(button);
    let qty = $(el)
      .parent()
      .find('input.qty');
    let val = parseFloat(qty.val());
    let maxVal = parseFloat(qty.attr('max'));
    let minVal = parseFloat(qty.attr('min'));
    let step = parseFloat(qty.attr('step'));

    if (el.is('.plus')) {
      if (maxVal && maxVal <= val) {
        qty.val(maxVal);
      } else {
        qty.val(val + step);
      }
    }

    if (el.is('.minus')) {
      if (minVal && minVal >= val) {
        qty.val(minVal);
      } else if (val > 1) {
        qty.val(val - step);
      }
    }

    this._updateCartTotals();
  }

  _updateCartTotals() {
    let cartForm = $('form.woocommerce-cart-form');
    this.disableElement(cartForm);
    this.disableElement($('div.cart_totals'));

    // Provide the submit button value because wc-form-handler expects it.
    $('<input />')
      .attr('type', 'hidden')
      .attr('name', 'update_cart')
      .attr('value', 'Update Cart')
      .appendTo(cartForm);

    // Make call to actual form post URL.
    $.ajax({
      type: cartForm.attr('method'),
      url: cartForm.attr('action'),
      data: cartForm.serialize(),
      dataType: 'html',
      success: response => {
        this._updateWcDiv(response);
      },
      complete: () => {
        this.enableElement(cartForm);
        this.enableElement($('div.cart_totals'));
      },
    });
  }

  disableElement(element) {
    if (!this.elementIsDisabled(element)) {
      element.addClass('processing').block({
        message: null,
        overlayCSS: {
          background: '#fff',
          opacity: 0.5,
        },
      });
    }
  }

  enableElement(element) {
    element.removeClass('processing').unblock();
  }

  elementIsDisabled(element) {
    return element.is('.processing') || element.parents('.processing').length;
  }

  _cartQuickAdd() {
    const quickAddButtons = Array.from(document.querySelectorAll('button.upsells-quick-add-cart'));

    quickAddButtons.forEach(button => {
      button.addEventListener('click', () => {
        button.querySelector('.spinner').classList.remove('hidden');
        button.classList.add('opacity-75');
        button.classList.add('pointer-events-none');

        const productId = button.getAttribute('data-product-id');
        const currentQuantityEl = document.querySelector(`.product-quantity[data-product-id='${productId}']`);

        if (currentQuantityEl) {
          const plusButton = currentQuantityEl.querySelector('.quantity-button.plus');
          this._changeQuanity(plusButton);
        }

        $.ajax({
          url: window.woocommerce_params.ajax_url,
          type: 'post',
          data: {
            action: 'add_drink_to_cart',
            productId: productId,
          },
          success: function(res) {
            console.log(res);
            button.querySelector('.spinner').classList.add('hidden');
            Toastr.options.positionClass = 'toast-top-left';
            Toastr.success('Product toegevoegd aan winkelmand!');
            button.classList.remove('opacity-75');
            button.classList.remove('pointer-events-none');
            $(document.body).trigger('wc_fragment_refresh');
          },
          complete: () => {
            this._updateCartTotals();
          },
        });
      });
    });
  }

  _updateWcDiv(html_str, preserve_notices) {
    let $html = $.parseHTML(html_str);
    let $new_form = $('.woocommerce-cart-form', $html);
    let $new_totals = $('.cart_totals', $html);
    let $notices = this._removeDuplicateNotices(
      $('.woocommerce-error, .woocommerce-message, .woocommerce-info', $html)
    );

    // No form, cannot do this.
    if ($('.woocommerce-cart-form').length === 0) {
      window.location.reload();
      return;
    }

    // Remove errors
    if (!preserve_notices) {
      $('.woocommerce-error, .woocommerce-message, .woocommerce-info').remove();
    }

    if ($new_form.length === 0) {
      // If the checkout is also displayed on this page, trigger reload instead.
      if ($('.woocommerce-checkout').length) {
        window.location.reload();
        return;
      }

      // No items to display now! Replace all cart content.
      let $cart_html = $('.cart-empty', $html).closest('.woocommerce');
      $('.woocommerce-cart-form__contents')
        .closest('.woocommerce')
        .replaceWith($cart_html);

      // Display errors
      if ($notices.length > 0) {
        this._showNotice($notices);
      }

      // Notify plugins that the cart was emptied.
      $(document.body).trigger('wc_cart_emptied');
    } else {
      // If the checkout is also displayed on this page, trigger update event.
      if ($('.woocommerce-checkout').length) {
        $(document.body).trigger('update_checkout');
      }

      $('.woocommerce-cart-form').replaceWith($new_form);

      $('.woocommerce-cart-form')
        .find(':input[name="update_cart"]')
        .prop('disabled', true)
        .attr('aria-disabled', true);

      if ($notices.length > 0) {
        this._showNotice($notices);
      }

      this._updateCartTotalsDiv($new_totals);
    }

    $(document.body).trigger('updated_wc_div');
  }

  _updateCartTotalsDiv(html_str) {
    $('.cart_totals').replaceWith(html_str);
    $(document.body).trigger('updated_cart_totals');
  }

  _removeDuplicateNotices(notices) {
    let seen = [];
    let new_notices = notices;

    notices.each(function(index) {
      let text = $(this).text();

      if ('undefined' === typeof seen[text]) {
        seen[text] = true;
      } else {
        new_notices.splice(index, 1);
      }
    });

    return new_notices;
  }

  _showNotice(html_element, $target) {
    if (!$target) {
      $target =
        $('.woocommerce-notices-wrapper:first') ||
        $('.cart-empty').closest('.woocommerce') ||
        $('.woocommerce-cart-form');
    }
    $target.prepend(html_element);
  }
}
